import * as React from 'react';

/** Stylesheet Imports */
import './Footer.css';

export interface Props {
}

export interface State {
}

export default class Footer extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <iframe
                id="footer-iframe"
                title="footer"
                width="100%"
                height="694px"
                style={{ border: 'none' }}
                seamless={true}
                src="https://www.visualcron.com/footercomponent.aspx"
            />
        );
    }
}
