import { FlagFilled } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import AnimationComponent from 'src/shared/components/AnimationComponent';

/** Stylesheet Imports */
import './HomeContainer.css';

export interface Props extends RouteComponentProps<{}> {
}

export default class HomeContainer extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="home-page-container">
                <AnimationComponent>
                    <Row gutter={[32, 32]}>
                        <Col span={24}>
                            <Card>
                                <div className="title text-center">
                                    <h3>What can our partners do for you?</h3>
                                    <hr />
                                    <p className="lead">Working with partners let you focus on your core business which you know best</p>
                                </div>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Configuration and installation',
                                            'Get started quickly by letting your partner handle configuration of VisualCron and its environment.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Implementation and migration',
                                            'Let your partner create Jobs for you and help you move from any other automation solution.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Development and integration',
                                            'Extend your company and the integration to VisualCron with the developers of your partner.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Training and support',
                                            'Enjoy training and support in your local language - at your pace.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Managed services',
                                            'A partner can manage existing Jobs, virtual servers, installed software and related services for you.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Help with purchasing and renewal',
                                            'Let your partner handle payments and renewals for you so you can focus on your core business.'
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        {this.getButtons()}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} >
                            <Card className="partners-image" />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <div className="title text-center">
                                    <h3>Benefits of a local partner</h3>
                                    <hr />
                                    <p className="lead">There are many advantages working with a local partner</p>
                                </div>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Help where you are',
                                            'A local partner can help you on-site.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Your time zone',
                                            'A local partner will share same or similar time zone as you.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Your language',
                                            'Interact in your preferred language.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Local payments and currency',
                                            'Purchase with your preferred payments method and currency.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Support your local dealer',
                                            'By employing a local talent you let your area and country grow.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Cultural differencies',
                                            'Do business the way you are used to locally.'
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        {this.getButtons()}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} >
                            <Card className="network-image" />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <div className="title text-center">
                                    <h3>Become a partner</h3>
                                    <hr />
                                    <p className="lead">We have customers that need help with training, implementation, configuration, renewal, development - locally</p>
                                </div>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Grow your business',
                                            'VisualCron helps you finding new customers.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Sell with instant kickback',
                                            'Discuss a profitably reseller model with us.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Get training and material',
                                            'VisualCron will provide you with training and material.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Capture market share',
                                            'The software automation industry is growing - join us!'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Create new revenue streams',
                                            'Sell, integrate, develop, support and train our customers.'
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {this.getLandingItem(
                                            'Be the local dealer',
                                            'We help you find customers in your area.'
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        {this.getButtons()}
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                </AnimationComponent>
            </div>
        );
    }

    private getButtons = () => {
        return <div className="partner-buttons">
            <Link
                style={{ width: '150px' }}
                to="/search"
            >
                <Button className="btn-success" type="primary" style={{ width: '150px' }}>
                    Find a partner
                </Button>
            </Link>
            <Link
                style={{ width: '150px' }}
                to="/register"
            >
                <Button className="btn-success" type="primary" style={{ width: '150px' }}>
                    Become a partner
                </Button>
            </Link>
        </div>;
    }

    private getLandingItem = (title: string, description: string) => {
        return <div className="service-square first effect-slide-bottom in" data-effect="slide-bottom">
            <div className="service-square-icon alignleft">
                <FlagFilled translate={undefined} />
            </div>
            <div className="title">
                <h6>{title}</h6>
            </div>
            <p>{description}</p>
        </div>;
    }
}