/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { STORE_PROFILE, STORE_ROUTER, STORE_USER } from 'src/constants';
/** Stylesheet Imports */
import './Menu.css';

import { inject, observer } from 'mobx-react';
import MobileNavigation from './MobileNavigation';
import { UserStore } from 'src/shared/stores/UserStore';
import { RouterStore } from 'mobx-react-router';
import { ProfileStore } from 'src/shared/stores/ProfileStore';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    searchText: string;
    dropdownOpen: {
        product: boolean,
        features: boolean,
        resources: boolean,
        about: boolean,
        search: boolean,
        account: boolean
    },
}

@inject(STORE_USER, STORE_ROUTER, STORE_PROFILE)
@observer
export default class Menu extends React.Component<Props, State> {
    public userStore: UserStore = this.props[STORE_USER];
    public routerStore: RouterStore = this.props[STORE_ROUTER];
    public profileStore: ProfileStore = this.props[STORE_PROFILE];

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            searchText: '',
            dropdownOpen: {
                product: false,
                features: false,
                resources: false,
                about: false,
                search: false,
                account: false
            },
        };
    }

    toggleDropdown = (dropdown: string) => {
        this.setState(prevState => ({
            dropdownOpen: {
                product: dropdown === 'product',
                features: dropdown === 'features',
                resources: dropdown === 'resources',
                about: dropdown === 'about',
                search: dropdown === 'search',
                account: dropdown === 'account'
            }
        }
        ));
    };
    onMouseLeaveDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: {
                product: false,
                features: false,
                resources: false,
                about: false,
                search: false,
                account: false
            }
        }
        ));
    };

    onSearchClickJS = () => {
        window.location.href = '/search.aspx';
        if (document.getElementById('dmsearch').classList.contains('dmsearch-open')) {
            window.location.href = '/search.aspx';
        }
        return false;
    };

    setSearchText = (e: string) => {
        this.setState(prevState => ({
            searchText: e
        }))
    }

    handleSearchClick = () => {
        const searchPattern = encodeURIComponent(this.state.searchText);
        window.location.href = `Search.aspx?searchPattern=${searchPattern}`;
    };

    render() {
        return (
            <header className="header clearfix affix-top">
                <div className="header-navbar header-container fhmm" role="navigation">
                    <div className="navbar-home">
                        <a href="/" title="Home">
                            <img className="header-logo" src="/VisualCronBlue.png" alt="VisualCron logo" />
                        </a>
                    </div>
                    <div className="header-mobile">
                        <MobileNavigation />
                    </div>

                    <div className="navbar-tabs header-collapse__mobile">
                        <ul className="header-navbar align-items-end">
                            <li className="logo-wrapper clearfix"></li>
                            <li onMouseLeave={this.onMouseLeaveDropdown} className={(this.state.dropdownOpen.product ? 'open ' : '') + "dropdown fhmm-fw h-100 header-collapse__md"}>
                                <a href="#" onMouseOver={() => this.toggleDropdown('product')} className="header-tab">
                                    <div>Product</div>
                                </a>
                                <div className="dropdown-menu fullwidth fhmm-fw tab-dropdown">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 text-nowrap">
                                                <div className="dropdown-category_header pt-2">VisualCron</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/about.aspx">What is VisualCron?</a></li>
                                                    <li><a href="https://visualcron.com/Why-Choose-VisualCron.aspx">Why choose VisualCron?</a></li>
                                                    <li><a href="https://visualcron.com/choosinglicense.aspx">Choosing the right license</a></li>
                                                    <li><a href="https://visualcron.com/basicvsprofessional.aspx">Compare licenses</a></li>
                                                    <li><a href="https://visualcron.com/licenseguide.aspx">License guide</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 dropdown-cta border-right border-left">
                                                <div className="dropdown-category_header dropdown-title">Request a Demo</div>
                                                <p className="dropdown-desc">Get a customized tour of VisualCron’s automation, integration, and task scheduling capabilities.</p>
                                                <a href="https://visualcron.com/request-demo.aspx" className="button button-blue">Request a Demo</a>
                                            </div>
                                            <div className="col-md-4 dropdown-cta">
                                                <div className="dropdown-category_header dropdown-title">Start Free Trial</div>
                                                <p className="dropdown-desc">Explore VisualCron’s full power at your own pace with a free, all-access trial for 30 days.</p>
                                                <a href="https://visualcron.com/download.aspx" className="button button-yellow">Start Free Trial</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li onMouseLeave={this.onMouseLeaveDropdown} className={(this.state.dropdownOpen.features ? 'open ' : '') + "dropdown fhmm-fw header-collapse"}>
                                <a href="#" onMouseOver={() => this.toggleDropdown('features')} className="header-tab">Features</a>
                                <div className="dropdown-menu fullwidth tab-dropdown">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">Overview</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/automation.aspx">General automation</a></li>
                                                    <li><a href="https://visualcron.com/Job-Scheduling-Software.aspx">Enterprise Job Scheduling</a></li>
                                                    <li><a href="https://visualcron.com/tasks.aspx">Tasks</a></li>
                                                    <li><a href="https://visualcron.com/triggers.aspx">Triggers</a></li>
                                                    <li><a href="https://visualcron.com/scheduler.aspx">Task Scheduler</a></li>
                                                    <li><a href="https://visualcron.com/connections.aspx">Connections</a></li>
                                                    <li><a href="https://visualcron.com/api.aspx">API</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">BPA</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/business-process-automation.aspx">Business Process Automation (BPA)</a></li>
                                                    <li><a href="https://visualcron.com/powershell-automation.aspx">PowerShell automation</a></li>
                                                    <li><a href="https://visualcron.com/cloud-automation.aspx">Cloud automation</a></li>
                                                    <li><a href="https://visualcron.com/encryption-automation.aspx">Encryption automation</a></li>
                                                    <li><a href="https://visualcron.com/sql-database-automation.aspx">SQL / database automation</a></li>
                                                    <li><a href="https://visualcron.com/pdf-automation.aspx">PDF automation & data extraction</a></li>
                                                    <li><a href="https://visualcron.com/xml-automation.aspx">XML automation & manipulation</a></li>
                                                    <li><a href="https://visualcron.com/office-macro-automation.aspx">Office 365, Excel & Macro Automation</a></li>
                                                    <li><a href="https://visualcron.com/sharepoint-automation.aspx">SharePoint automation</a></li>
                                                    <li><a href="https://visualcron.com/7z-zip-rar-archive-automation.aspx">Archive automation</a></li>
                                                    <li><a href="https://visualcron.com/vm-virtual-machine-automation.aspx">Virtual Machine Automation</a></li>
                                                    <li><a href="https://visualcron.com/scan-document-ocr-invoice.aspx">Document scanning / OCR</a></li>
                                                    <li><a href="https://visualcron.com/workflowmanagementsoftware.aspx">Workflow Management</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">MFT</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/mft-managed-file-transfer.aspx">Managed File Transfer (MFT)</a></li>
                                                    <li><a href="https://visualcron.com/ftp-sftp-scp-automation.aspx">FTP/SFTP/SCP automation</a></li>
                                                    <li><a href="https://visualcron.com/exe-batch-automation.aspx">Batch & exe automation</a></li>
                                                    <li><a href="https://visualcron.com/etl-extract-transform-load.aspx">Extract Transform Load (ETL)</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li onMouseLeave={this.onMouseLeaveDropdown} className={(this.state.dropdownOpen.resources ? 'open ' : '') + "dropdown fhmm-fw header-collapse"}>
                                <a href="#" onMouseOver={() => this.toggleDropdown('resources')} className="header-tab">Resources</a>
                                <div className="dropdown-menu fullwidth tab-dropdown">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">Resources</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li>
                                                        <a href="https://www.visualcron.com/blog/default.aspx">Blog</a>
                                                    </li>
                                                    <li><a href="https://www.visualcron.com/documentation.aspx">Documentation</a></li>
                                                    <li><a href="https://visualcron.com/forum.aspx">Community forum</a></li>
                                                    <li><a href="https://visualcron.com/tutorials.aspx">Video tutorials</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">System Support</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/support.aspx">Options</a></li>
                                                    <li><a href="https://visualcron.com/requirements.aspx">System Requirements</a></li>
                                                    <li><a href="https://visualcron.com/changelog.aspx">Change Log</a></li>
                                                    <li><a href="https://visualcron.com/contact.aspx">Contact Support</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">Order Support</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/getpricequote.aspx">Price Quotes</a></li>
                                                    <li><a href="https://visualcron.com/poinvoice.aspx">POs/Invoicing</a></li>
                                                    <li><a href="https://visualcron.com/paymentinformation.aspx">Payment Information</a></li>
                                                    <li><a href="https://visualcron.com/returnpolicy.aspx">Return Policy</a></li>
                                                    <li><a href="https://visualcron.com/resellerinformation.aspx">Resellers</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>

                            <li onMouseLeave={this.onMouseLeaveDropdown} className={(this.state.dropdownOpen.about ? 'open ' : '') + "dropdown fhmm-fw header-collapse"}>
                                <a href="#" onMouseOver={() => this.toggleDropdown('about')} className="header-tab">About</a>
                                <div className="dropdown-menu fullwidth tab-dropdown">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="dropdown-category_header pt-2">About Us</div>
                                                <hr className="dropdown-ruler" />
                                                <ul className="dropdown-menu_list">
                                                    <li><a href="https://visualcron.com/customers.aspx">Customers</a></li>
                                                    <li><a href="https://partners.visualcron.com/">Partners</a></li>
                                                    <li><a href="https://visualcron.com/testimonials.aspx">Testimonials</a></li>
                                                    <li><a href="https://visualcron.com/awards.aspx">Awards</a></li>
                                                    <li><a href="https://visualcron.com/contact.aspx">Contact Us</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="fhmm-fw"><a href="https://visualcron.com/order.aspx" className="header-tab">Pricing</a></li>
                        </ul>
                    </div>
                    <div className="header-account">
                        <div onMouseLeave={this.onMouseLeaveDropdown} className={(this.state.dropdownOpen.search ? 'open ' : '') + "fhmm-fw"}>
                            <a href="/search.aspx" data-toggle="dropdown" className="dmsearch" onMouseOver={() => this.toggleDropdown('search')}>
                                <div className="dmsearch">
                                    <span className="header-searchicon" onMouseDown={() => this.onSearchClickJS()}></span>
                                </div>
                            </a>

                            <div className="dropdown-menu fullwidth tab-dropdown">
                                <div className="dropdown-search">
                                    <input
                                        type="text"
                                        id="s"
                                        className="search-textbox"
                                        value={this.state.searchText}
                                        onChange={(e) => this.setSearchText(e.target.value)}
                                        placeholder=""
                                    />
                                    <button
                                        id="go"
                                        className="search-button"
                                        onClick={this.handleSearchClick}
                                    >
                                        <i className="fa fa-search"></i> Search
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div className="header-ruler"></div>
                        <div className="account-bar">
                            <a href="https://visualcron.com/contact.aspx" className="header-tab">Contact Sales</a>
                        </div>

                    </div>
                </div>
            </header>
        );
    }
}
