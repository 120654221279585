import { History, LocationState } from 'history';
import {
    RouterStore as BaseRouterStore,
    syncHistoryWithStore
} from 'mobx-react-router';

export class RouterStore extends BaseRouterStore {
    public onLocationChange: () => void;

    constructor(history?: History) {
        super();

        if (history) {
            this.history = syncHistoryWithStore(history, this);
            this.history.listen(this.locationChanged);
        }
    }

    locationChanged = (location: LocationState) => {
        if (this.onLocationChange) {
            this.onLocationChange();
        }
    }
}

export default RouterStore;
