import { Row, Col, Pagination, Input, Card, Select, Checkbox, Typography, Badge, InputRef } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { STORE_PROFILE, STORE_ROUTER, STORE_USER } from 'src/constants';
import PartnerServiceLocation from 'src/models/PartnerServiceLocation';
import PartnerServicesProvided from 'src/models/PartnerServicesProvided';
import LoaderComponent from 'src/shared/components/LoaderComponent';
import SignalrHelper from 'src/shared/helpers/signalrHelper';
import { ProfileStore } from 'src/shared/stores/ProfileStore';
import ReactCountryFlag from 'react-country-flag';
import * as QueryString from 'query-string';
import CountryModel from 'src/models/CountryModel';
import FilterViewModel from 'src/models/FilterViewModel';

/** Stylesheet Imports */
import './SearchComponent.css';
import RouterStore from 'src/shared/stores/RouterStore';
import PartnerMarketingMethod from 'src/models/PartnerMarketingMethod';
import { UserStore } from 'src/shared/stores/UserStore';

const { Option } = Select;
const { Text } = Typography;

export interface Props {
    showAll: boolean;
}

export interface State {
    partnerServicesProvided: PartnerServicesProvided[];
    partnerServiceLocation: PartnerServiceLocation[];
    partnerMarketingMethod: PartnerMarketingMethod[];
    countries: CountryModel[];
    cityes: string[];
    providedLoading: boolean;
    serviceLoading: boolean;
}

@inject(STORE_PROFILE, STORE_ROUTER, STORE_USER)
@observer
export default class SearchComponent extends React.Component<Props, State> {
    public profileStore: ProfileStore = this.props[STORE_PROFILE];
    public router: RouterStore = this.props[STORE_ROUTER];
    public userStore: UserStore = this.props[STORE_USER];

    public signalr = new SignalrHelper();
    public textInput: InputRef;

    constructor(props: Props) {
        super(props);

        this.state = {
            partnerServicesProvided: [],
            partnerServiceLocation: [],
            partnerMarketingMethod: [],
            providedLoading: true,
            serviceLoading: true,
            countries: [],
            cityes: []
        };
    }

    componentDidMount() {
        this.getServiceLocations();
        this.getServiceProvided();
        this.getMarketingMethods();
        this.getCountries();
        this.readUrlParams();
        this.textInput.focus();
    }

    getCountries = () => {
        this.signalr.invoke('GetCountries').then(
            (data: CountryModel[]) => {
                this.setState({ countries: data }, () => {
                    this.getCountryFromStorage();
                });
            }
        );
    }

    getCitiesByCountry = () => {
        this.signalr.invoke('GetCityByCountry', this.profileStore.filter.country).then(
            (data: string[]) => {
                this.setState({ cityes: data });
            }
        );
    }

    getCountryFromStorage = () => {
        const countryCode = localStorage.getItem('CountryCode');
        if (countryCode) {
            this.profileStore.filter.country = countryCode;
        }
    }

    getServiceLocations = () => {
        this.signalr.invoke('GetServiceLocations').then(
            (data: PartnerServiceLocation[]) => {
                this.setState({ partnerServiceLocation: data, serviceLoading: false });
            }
        );
    }

    getServiceProvided = () => {
        this.signalr.invoke('GetServiceProvided').then(
            (data: PartnerServicesProvided[]) => {
                this.setState({ partnerServicesProvided: data, providedLoading: false });
            }
        );
    }

    getMarketingMethods = () => {
        this.signalr.invoke('GetMarketingMethods').then(
            (data: PartnerMarketingMethod[]) => {
                const i = new PartnerMarketingMethod();
                i.id = -1;
                i.name = 'Not set';
                data.push(i);
                this.setState({ partnerMarketingMethod: data });
            }
        );
    }

    search = () => {
        this.profileStore.getProfiles();
        this.setUpUrlParams();
    }

    render() {
        return (
            <div className="search-profile-component">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h4>Find a partner</h4>
                    </Col>
                    <Col span={8}>
                        <p className="subtitle">Name</p>
                        <Input
                            ref={input => {
                                this.textInput = input;
                            }}
                            placeholder="Company name"
                            value={this.profileStore.filter.companyName}
                            onChange={this.onChangeSearch}
                        />
                    </Col>
                    <Col span={8}>
                        <p className="subtitle">Country</p>
                        <Select style={{ width: '100%' }} loading={this.state.countries.length === 0} optionFilterProp="children" value={this.profileStore.filter.country} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeCountryCode} >
                            <Option key={'All'} value={'All'}>All</Option>
                            {this.state.countries.map(country => (
                                <Option key={country.code} value={country.code}>
                                    <ReactCountryFlag
                                        countryCode={country.code}
                                        svg={true}
                                        style={{
                                            height: '16px',
                                            marginTop: -2
                                        }}
                                        cdnSuffix="svg"
                                    />
                                    {' ' + this.titleCase(country.name)}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <p className="subtitle">City</p>
                        <Select style={{ width: '100%' }} optionFilterProp="children" value={this.profileStore.filter.city} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeCity} >
                            <Option key={'All'} value={'All'}>All</Option>
                            {this.state.cityes.map(city => (
                                <Option key={city} value={city}>{city}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <p className="subtitle">Service provided</p>
                        <Select loading={this.state.providedLoading} style={{ width: '100%' }} mode="multiple" optionFilterProp="children" value={!this.state.providedLoading && this.profileStore.filter.serviceProvided} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeSelectValue(e, 'serviceProvided')} >
                            {!this.state.providedLoading && this.state.partnerServicesProvided.map(e => (
                                <Option key={e.id} value={e.id}>{e.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <p className="subtitle">Service location</p>
                        <Select loading={this.state.serviceLoading} style={{ width: '100%' }} mode="multiple" optionFilterProp="children" value={!this.state.serviceLoading && this.profileStore.filter.serviceLocation} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeSelectValue(e, 'serviceLocation')} >
                            {!this.state.serviceLoading && this.state.partnerServiceLocation.map(e => (
                                <Option key={e.id} value={e.id}>{e.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    {this.userStore.isAdmin &&
                        <Col span={24}>
                            <p className="subtitle">Marketing method</p>
                            <Select style={{ width: '100%' }} mode="multiple" optionFilterProp="children" value={this.profileStore.filter.marketingMethod} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeSelectValue(e, 'marketingMethod')} >
                                {this.state.partnerMarketingMethod.map(e => (
                                    <Option key={e.id} value={e.id}>{e.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    }
                    <Col span={24}>
                        <Checkbox value={this.profileStore.filter.featured} onChange={this.onChangeFeatured}>Featured partner</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            {this.getCustomers()}
                        </Row>
                    </Col>
                    {this.profileStore.searchResults.length !== 0 &&
                        <Col span={24} className="right-align">
                            <Pagination
                                current={this.profileStore.page}
                                pageSize={this.profileStore.offset}
                                total={this.profileStore.searchResults.length}
                                pageSizeOptions={[
                                    24, 48, 92
                                ]}
                                onChange={this.onChangePage}
                            />
                        </Col>
                    }
                    <Col span={24} >
                        <h5>Cannot find a partner?</h5>
                        <p>If you have a preferred partner or want to work with a local one please <a href="mailto:sales@visualcron.com">send us a message</a> including partner name, website, contact name, contact email, contact phone number</p>
                    </Col>
                </Row>
            </div >
        );
    }

    private getCustomers = () => {
        if (this.profileStore.loadingFilter) {
            return <div style={{ width: '100%', padding: 64 }}>
                <LoaderComponent />
            </div>;
        } else {
            let models = this.profileStore.searchResults;
            if (!this.props.showAll) {
                models = models.slice(0, 6);
            }
            return models.slice((this.profileStore.page - 1) * this.profileStore.offset, (this.profileStore.page - 1) * this.profileStore.offset + this.profileStore.offset).map(item => (
                <Col xs={8} sm={6} md={4} key={item.customerId} className="course-card">
                    <Link
                        to={'/profile:' + item.customerId}
                    >
                        {this.getCardBadge(
                            item.featured,
                            <Card
                                hoverable={true}
                                cover={item.logoLoaded ? <img alt="example" src={item.logo ? item.logo : '/VisualCronEmpyImage.png'} /> : <div className="loading-logo" />}
                            >
                                <Meta
                                    title={item.companyName}
                                    description={<>

                                        <Text
                                            style={{ width: '100%' }}
                                            ellipsis={{ tooltip: this.titleCase(item.country) + (item.city ? ', ' + item.city : '') }}
                                        >
                                            <ReactCountryFlag
                                                countryCode={item.countryCode}
                                                svg={true}
                                                style={{
                                                    height: '16px',
                                                    marginTop: -2
                                                }}
                                                cdnSuffix="svg"
                                            />
                                            {' ' + this.titleCase(item.country) + (item.city ? ', ' + item.city : '')}
                                        </Text>

                                    </>}
                                />
                            </Card>
                        )}
                    </Link>
                </Col>
            ));
        }
    }

    private setUpUrlParams = () => {
        const params: string[] = [];
        const model = this.profileStore.filter;

        const defaultModel = new FilterViewModel();

        Object.getOwnPropertyNames(model).forEach(x => {
            if (model[x] !== defaultModel[x]) {
                if (x !== 'serviceProvided' && x !== 'serviceLocation') {
                    params.push(x + '=' + model[x]);
                }
            }
        });

        const urlParams = '/search' + (params.length ? '?' + params.join('&') : '');
        if (window.location.pathname !== urlParams) {
            window.history.pushState('', '', urlParams);
        }
    }

    private getCardBadge = (featured: boolean, child: JSX.Element) => {
        if (featured) {
            return <Badge.Ribbon text="Featured" color="#21C2F8">{child}</Badge.Ribbon>;
        } else {
            return child;
        }
    }

    private titleCase = (str: string) => {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    private changeCountryCode = (value: any) => {
        this.profileStore.filter.country = value;
        this.getCitiesByCountry();
        this.search();
    }

    private changeCity = (value: any) => {
        this.profileStore.filter.city = value;
        this.search();
    }

    private onChangeFeatured = (e: any) => {
        this.profileStore.filter.featured = e.target.checked;
        this.search();
    }

    private changeSelectValue = (value: any, name: string) => {
        this.profileStore.filter[name] = value;
        this.search();
    }

    private onChangePage = (page: number, pageSize?: number) => {
        this.profileStore.page = page;
        this.profileStore.offset = pageSize;
        this.profileStore.getLogos();
    }

    private onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.profileStore.filter.companyName = e.target.value;
        this.search();
        if (this.router.location.pathname !== '/search') {
            this.router.push('/search');
        }
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);

        for (const propertyName in params) {
            if (this.profileStore.filter[propertyName] !== undefined) {
                this.profileStore.filter[propertyName] = params[propertyName];
            }
        }

        this.profileStore.getLocation();
    }
}
