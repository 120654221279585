import * as signalR from '@microsoft/signalr';

export default class SignalrHelper {
    public connection: signalR.HubConnection;

    async invoke(methodName: string, ...params: any[]) {
        try {
            if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
                return await this.connection.invoke(methodName, ...params);
            } else {
                await this.connect();
                return await this.connection.invoke(methodName, ...params);
            }
        } catch (exc) {
            console.log(methodName, params, exc);
        }
    }

    async connect() {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${window.location.origin}/reacthub`)
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        connection.serverTimeoutInMilliseconds = 1000000;

        await connection.start().then(() => {
            this.connection = connection;
        });
    }
}