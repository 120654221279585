import api from '../constants/api';
import ApiService from './ApiService';

export default class ProfileService extends ApiService {

    public async getProfile(customerId: string) {
        const res = await this.apiCall(
            api.getProfile + '?customerIdStr=' + customerId,
            'GET'
        );
        this.handleCommonError(res, false);

        return res.body;
    }

    public async getLocation() {
        const res = await this.apiCall(
            api.getLocation,
            'GET'
        );
        this.handleCommonError(res, false);

        return res.body;
    }

    public async getProfiles(json: string) {
        const res = await this.apiCall(
            api.getProfiles,
            'POST',
            false,
            json
        );
        this.handleCommonError(res, false);

        return res.body;
    }

    public async getLogos(json: string) {
        const res = await this.apiCall(
            api.getLogos,
            'POST',
            false,
            json
        );
        this.handleCommonError(res, false);

        return res.body;
    }
}