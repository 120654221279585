import { Button, Col, Form, FormInstance, Input, notification, Row, Select, Steps } from 'antd';
import { Editor, EditorChangeEvent, EditorTools } from '@progress/kendo-react-editor';
import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './RegisterLogin.css';
import '@progress/kendo-theme-default/dist/all.css';
import Cookies from 'universal-cookie';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import BusinessCategoryModel from './models/BusinessCategoryModel';
import BusinessSubCategoryModel from './models/BusinessSubCategoryModel';
import LoginModel from './models/LoginModel';
import PermissionModel from './models/PermissionModel';
import RegisterModel from './models/RegisterModel';
import RoleModel from './models/RoleModel';
import HowHearModel from 'src/models/HowHearModel';
import CountryModel from 'src/models/CountryModel';
import SignalrHelper from 'src/shared/helpers/signalrHelper';
import SpinLoader from 'src/shared/components/SpinLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import EmployeeCount from 'src/models/EmployeeCount';
import PartnerServiceLocation from 'src/models/PartnerServiceLocation';
import PartnerServicesProvided from 'src/models/PartnerServicesProvided';
import ProfileService from 'src/services/ProfileService';

const { Option } = Select;
const { Step } = Steps;

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

export interface Props {
    tab?: string;
    lightVersion?: boolean;
    lightRedirect?: string;
    whitePaper?: boolean;
}

export interface State {
    registerModel: RegisterModel;
    registerLoaded: boolean;
    showUnableEmail: boolean;
    countries: CountryModel[];
    captchaPassed: boolean;
    roles: RoleModel[];
    permissions: PermissionModel[];
    businessCategories: BusinessCategoryModel[];
    businessSubCategories: BusinessSubCategoryModel[];
    protocol: string;
    loginModel: LoginModel;
    succesfullyCreated: boolean;
    registerLoading: boolean;
    howHearTypes: HowHearModel[];
    r: string;
    DestinationPageUrl: string;
    emailApproved: boolean;
    tab: string;
    linkedInLoading: boolean;
    state: string;
    step: number;
    partnerServicesProvided: PartnerServicesProvided[];
    partnerServiceLocation: PartnerServiceLocation[];
    employeeCount: EmployeeCount[];
    c: string;
}

export default class RegisterLogin extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    loginRedirectUrl = '/myprofile.aspx';
    public formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);

        const tab = this.props.tab ? this.props.tab as string : 'Register';

        this.state = {
            registerModel: new RegisterModel(),
            showUnableEmail: false,
            countries: [],
            captchaPassed: false,
            roles: [],
            permissions: [],
            businessCategories: [],
            businessSubCategories: [],
            protocol: 'https://',
            loginModel: new LoginModel(),
            succesfullyCreated: false,
            registerLoading: false,
            howHearTypes: [],
            r: '',
            DestinationPageUrl: '',
            emailApproved: false,
            tab,
            linkedInLoading: false,
            state: '',
            registerLoaded: false,
            step: 0,
            partnerServicesProvided: [],
            partnerServiceLocation: [],
            employeeCount: [],
            c: ''
        };
    }

    componentDidMount() {
        this.getLocation();

        this.getCountries();
        this.getRoles();
        this.getPermissions();
        this.getBusinessCategories();
        this.getBusinessSubCategories();
        this.getHowHearTypes();
        this.getServiceLocations();
        this.getServiceProvided();
        this.getEmployeeCounts();
        this.readUrlParams();
    }

    onEmailApprove = () => {
        const loginModel = this.state.loginModel;
        loginModel.login = this.state.registerModel.email;
        this.setState({ emailApproved: true, loginModel });
    }

    getLocation = () => {
        const api = new ProfileService();
        api.getLocation().then(
            (data: string) => {
                const registerModel = this.state.registerModel;
                registerModel.countryCode = data;
                if (this.state.countries.find(x => x.code === data)) {
                    registerModel.phonePrefix = '+' +
                        this.state.countries.find(x => x.code === data)?.phonePrefix.toString();
                }

                this.setState({ registerModel });
                localStorage.setItem('CountryCode', data);
                this.getCountryFromStorage();
            });
    }

    getLinkedInUrl = () => {
        this.signalr.invoke('GetLinkedInUrl').then(
            (data: string) => {
                window.location.replace(data);
            }
        );
    }

    getCountryFromStorage = () => {
        const countryCode = localStorage.getItem('CountryCode');
        if (countryCode) {
            const registerModel = this.state.registerModel;
            registerModel.countryCode = countryCode;
            if (this.state.countries.find(x => x.code === countryCode)) {
                registerModel.phonePrefix = '+' +
                    this.state.countries.find(x => x.code === countryCode)?.phonePrefix.toString();
            }

            this.setState({ registerModel });
        }
    }

    getCountries = () => {
        this.signalr.invoke('GetCountries').then(
            (data: CountryModel[]) => {
                this.setState({ countries: data }, () => {
                    this.getCountryFromStorage();
                });
            }
        );
    }

    getRoles = () => {
        this.signalr.invoke('GetRoles').then(
            (data: RoleModel[]) => {
                this.setState({ roles: data.filter(x => x.id !== 0) });
            }
        );
    }

    getPermissions = () => {
        this.signalr.invoke('GetPermissions').then(
            (data: PermissionModel[]) => {
                this.setState({ permissions: data });
            }
        );
    }

    getBusinessCategories = () => {
        this.signalr.invoke('GetBusinessCategories').then(
            (data: BusinessCategoryModel[]) => {
                this.setState({ businessCategories: data });
            }
        );
    }

    getBusinessSubCategories = () => {
        this.signalr.invoke('GetBusinessSubCategories').then(
            (data: BusinessSubCategoryModel[]) => {
                this.setState({ businessSubCategories: data });
            }
        );
    }

    getHowHearTypes = () => {
        this.signalr.invoke('GetHowHearTypes').then(
            (data: HowHearModel[]) => {
                this.setState({ howHearTypes: data });
            }
        );
    }

    getServiceLocations = () => {
        this.signalr.invoke('GetServiceLocations').then(
            (data: PartnerServiceLocation[]) => {
                this.setState({ partnerServiceLocation: data });
            }
        );
    }

    getServiceProvided = () => {
        this.signalr.invoke('GetServiceProvided').then(
            (data: PartnerServicesProvided[]) => {
                this.setState({ partnerServicesProvided: data });
            }
        );
    }

    getEmployeeCounts = () => {
        this.signalr.invoke('GetEmployeeCounts').then(
            (data: EmployeeCount[]) => {
                this.setState({ employeeCount: data });
            }
        );
    }

    getValidateMessages = () => {
        return {
            // eslint-disable-next-line no-template-curly-in-string
            required: '${label} is required',
            types: {
                email: 'The email format is not valid',
                // eslint-disable-next-line no-template-curly-in-string
                number: '${label} is not a valid number',
            },
        };
    }

    onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const registerModel = this.state.registerModel;
        const name = event.target.name as string;
        (registerModel as any)[name] = event.target.value;
        this.setState({ registerModel });
    }

    onChangeLoginField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const loginModel = this.state.loginModel;
        const name = event.target.name as string;
        (loginModel as any)[name] = event.target.value;
        this.setState({ loginModel });
    }

    onChangeTextAreaField = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const registerModel = this.state.registerModel;
        const name = event.target.name as string;
        (registerModel as any)[name] = event.target.value;
        this.setState({ registerModel });
    }

    checkEmail = (event: any) => {
        const registerModel = this.state.registerModel;
        registerModel.email = event.target.value;
        this.setState({ registerModel });

        if (event.target.value.includes('@') && event.target.value.includes('.')) {
            this.signalr.invoke('GetEmailValid', event.target.value).then(
                (data: boolean) => {
                    this.setState({ showUnableEmail: data });
                }
            );
        }
    }

    changePhonePrefix = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.phonePrefix = value;
        this.setState({ registerModel });
    }

    changeCountryCode = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.countryCode = value;
        registerModel.phonePrefix = '+' + this.state.countries.find(x => x.code === value)?.phonePrefix.toString();
        this.setState({ registerModel });
    }

    changeRole = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.role = +value;
        this.setState({ registerModel });
    }

    changePermission = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.permission = +value;
        this.setState({ registerModel });
    }

    changeHowDidHear = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.howdidhear = +value;
        this.setState({ registerModel });
    }

    changeBusinessCategory = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.businessCategory = +value;
        this.setState({ registerModel });
    }

    changeBusinessSubCategory = (value: any) => {
        const registerModel = this.state.registerModel;
        registerModel.businessSubCategory = +value;
        this.setState({ registerModel });
    }

    changeEnumValue = (value: any, name: string) => {
        const registerModel = this.state.registerModel;
        registerModel[name] = +value;
        this.setState({ registerModel });
    }

    changeArrayEnumValue = (value: any, name: string) => {
        const model = this.state.registerModel;
        (model as any)[name] = value;
        this.setState({ registerModel: model });
    }

    onChangeProtocol = (value: any) => {
        this.setState({ protocol: value });
    }

    onChangeLoginRemember = (e: CheckboxChangeEvent) => {
        const loginModel = this.state.loginModel;
        loginModel.remember = e.target.checked;
        this.setState({ loginModel });
    }

    onFinish = (values: any) => {
        if (!this.state.showUnableEmail) {
            this.register();
        }
    }

    onLoginFinish = (values: any) => {
        this.login();
    }

    login = () => {
        let redirect = this.state.r ? this.state.r : this.state.DestinationPageUrl.replace('~/', '');
        if (!redirect) {
            redirect = this.loginRedirectUrl;
        }

        this.signalr.invoke('CheckUserApproved', this.state.loginModel.login).then(
            (data: string) => {
                if (data) {
                    notification.error({
                        message: 'Error',
                        description: data
                    });
                } else {
                    this.signalr.invoke(
                        'Login',
                        this.state.loginModel,
                        window.location.protocol + '//' + window.location.host
                    ).then(
                        (data: string) => {
                            if (!data) {
                                notification.error({
                                    message: 'Incorrect login or password',
                                    description: data
                                });
                            } else {
                                const cookies = new Cookies();
                                cookies.set('YAFNETAuthentication', data, { path: '/' });

                                if (!this.props.lightRedirect) {
                                    window.location.replace(redirect);
                                } else {
                                    if (this.props.lightRedirect.endsWith('.exe')) {
                                        window.location.replace(window.location.href);
                                    } else {
                                        window.location.replace(this.props.lightRedirect);
                                    }
                                }
                            }
                        }
                    );
                }
            }
        );
    }

    register = () => {
        this.setState({ registerLoading: true }, () => {
            const model = this.state.registerModel;
            model.website = model.website ? (this.state.protocol + model.website) : '';
            model.addressline = model.addressline ? model.addressline : '';
            model.addressline2 = model.addressline2 ? model.addressline2 : '';
            model.city = model.city ? model.city : '';
            model.zipcode = model.zipcode ? model.zipcode : '';

            model.campaign = this.state.c;

            const connectionId = this.signalr.connection.connectionId;
            const r = this.state.r ? this.state.r : this.props.lightRedirect;

            this.signalr.invoke(
                'Register',
                model,
                window.location.protocol + '//' + window.location.host,
                r,
                this.props.lightVersion ? connectionId : ''
            ).then(
                (data: string) => {
                    this.setState({ registerLoading: false });
                    if (data) {
                        notification.error({
                            message: 'Error',
                            description: data
                        });
                    } else {
                        this.setState({ succesfullyCreated: true });
                    }
                }
            );
        });
    }

    changeTab = (activeKey: string) => {
        this.setState({ tab: activeKey });
    }

    signUpWithLinkedIn = () => {
        const registerData = JSON.stringify(this.state.registerModel);
        localStorage.setItem('RegisterData', registerData);
        this.setState({ linkedInLoading: true }, () => {
            this.getLinkedInUrl();
        });
    }

    signInWithLinkedIn = () => {
        this.setState({ linkedInLoading: true }, () => {
            this.getLinkedInUrl();
        });
    }

    onChangeDescription = (event: EditorChangeEvent) => {
        const registerModel = this.state.registerModel;
        registerModel.description = event.html;
        this.setState({ registerModel });
    }

    render() {
        return (
            <Row className="register-login-form reactcss">
                {!this.props.lightVersion &&
                    <Col span={4} />
                }
                <Col span={!this.props.lightVersion ? 16 : 24}>
                    {!this.state.succesfullyCreated &&
                        <>
                            {this.props.lightVersion &&
                                <p style={{
                                    padding: 8,
                                    background: '#dff0d8',
                                    border: '1px solid #d6e9c6',
                                    borderRadius: 4
                                }}
                                >
                                    {this.props.whitePaper ? 'You are just one step from downloading the white paper! ' : 'You are just one step from download and starting your free 30 day trial! '}Just fill in the information below to register or click <Button style={{ padding: 0 }} type="link" onClick={() => { this.setState({ tab: 'Login' }); }}>Login</Button> to use your existing account</p>
                            }
                            {!this.state.registerLoaded &&
                                <SpinLoader />
                            }
                            {this.state.registerLoaded &&
                                <Form
                                    ref={this.formRef}
                                    layout={'vertical'}
                                    validateMessages={this.getValidateMessages()}
                                    onFinish={this.onFinish}
                                    requiredMark={'optional'}
                                    className={this.props.lightVersion ? 'light-version' : 'full-version'}
                                >

                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Steps current={this.state.step}>
                                                <Step title="Company" />
                                                <Step title="User" />
                                                <Step title="Partner" />
                                            </Steps>
                                        </Col>
                                        {this.state.step === 1 &&
                                            <Col span={24}>
                                                <Form.Item
                                                    label="First name"
                                                    name="firstName"
                                                    initialValue={this.state.registerModel.firstName}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },

                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.firstName}
                                                        name="firstName"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Last name"
                                                    name="lastName"
                                                    initialValue={this.state.registerModel.lastName}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.lastName}
                                                        name="lastName"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    initialValue={this.state.registerModel.email}
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                        },
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.email}
                                                        onChange={this.checkEmail}
                                                    />
                                                </Form.Item>
                                                {this.state.showUnableEmail &&
                                                    <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">This does not seem to be a business email. Please use a business email.</div></div>
                                                }
                                                <Form.Item
                                                    label="Password"
                                                    name="password"
                                                    initialValue={this.state.registerModel.password}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        value={this.state.registerModel.password}
                                                        name="password"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Confirm password"
                                                    name="confirmPassword"
                                                    dependencies={['password']}
                                                    initialValue={this.state.registerModel.confirmPassword}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please confirm your password',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('The two passwords that you entered do not match'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password
                                                        value={this.state.registerModel.confirmPassword}
                                                        name="confirmPassword"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Country"
                                                    name="countryCode"
                                                    id="countryCode"
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    {this.state.countries.length !== 0 &&
                                                        <Select loading={this.state.countries.length === 0} optionFilterProp="children" value={this.state.registerModel.countryCode} defaultValue={this.state.registerModel.countryCode} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeCountryCode} >
                                                            {this.state.countries.map(country => (
                                                                <Option key={country.code} value={country.code}>{this.getCountry(country.name)}</Option>
                                                            ))}
                                                        </Select>
                                                    }
                                                </Form.Item>
                                                <Form.Item
                                                    id="bookPhoneNumber"
                                                    label="Phone number"
                                                    name="phone"
                                                    initialValue={this.state.registerModel.phone}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.phone}
                                                        name="phone"
                                                        addonBefore={
                                                            <Form.Item noStyle={true} >
                                                                <Select style={{ width: 90 }} showSearch={true} value={this.state.registerModel.phonePrefix} onChange={this.changePhonePrefix} >
                                                                    {this.getPhonePrefixes().map(phone => (
                                                                        <Option key={phone} value={phone}>{phone}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        }
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Role"
                                                    name="role"
                                                    initialValue={this.state.registerModel.role}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select optionFilterProp="children" value={this.state.registerModel.role} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeRole} >
                                                        {this.state.roles.map(role => (
                                                            <Option key={role.id} value={role.id}>{role.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                {/* <Form.Item
                                                    label="Permission"
                                                    name="permission"
                                                    initialValue={this.state.registerModel.permission}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select optionFilterProp="children" value={this.state.registerModel.permission} className="btn" showSearch placeholder="Please select" onChange={this.changePermission} >
                                                        {this.state.permissions.map(permission => (
                                                            <Option value={permission.id}>{permission.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item> */}
                                            </Col>
                                        }
                                        {this.state.step === 0 &&
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Company name"
                                                    name="company"
                                                    initialValue={this.state.registerModel.company}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.company}
                                                        name="company"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                {!this.props.lightVersion &&
                                                    <>
                                                        <Form.Item
                                                            label="Business category"
                                                            name="businessCategory"
                                                            initialValue={this.state.registerModel.businessCategory}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Select optionFilterProp="children" value={this.state.registerModel.businessCategory} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeBusinessCategory} >
                                                                {this.state.businessCategories.map(businessCategory => (
                                                                    <Option key={businessCategory.id} value={businessCategory.id}>{businessCategory.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Business sub category"
                                                            name="businessSubCategory"
                                                            initialValue={this.state.registerModel.businessSubCategory}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Select optionFilterProp="children" value={this.state.registerModel.businessSubCategory} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeBusinessSubCategory} >
                                                                {this.getFilteredBusinessSubCategories().map(businessSubCategory => (
                                                                    <Option key={businessSubCategory.id} value={businessSubCategory.id}>{businessSubCategory.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Website url"
                                                            name="website"
                                                            initialValue={this.state.registerModel.website}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                addonBefore={
                                                                    <Select value={this.state.protocol} className="select-before" onChange={this.onChangeProtocol}>
                                                                        <Option value="http://">http://</Option>
                                                                        <Option value="https://">https://</Option>
                                                                    </Select>
                                                                }
                                                                value={this.state.registerModel.website}
                                                                name="website"
                                                                onChange={this.onChangeField}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Address line"
                                                            name="addressline"
                                                            initialValue={this.state.registerModel.addressline}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                value={this.state.registerModel.addressline}
                                                                name="addressline"
                                                                onChange={this.onChangeField}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Address line 2"
                                                            name="addressline2"
                                                            initialValue={this.state.registerModel.addressline2}
                                                        >
                                                            <Input
                                                                value={this.state.registerModel.addressline2}
                                                                name="addressline2"
                                                                onChange={this.onChangeField}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Zip code"
                                                            name="zipcode"
                                                            initialValue={this.state.registerModel.zipcode}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                value={this.state.registerModel.zipcode}
                                                                name="zipcode"
                                                                onChange={this.onChangeField}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="City"
                                                            name="city"
                                                            initialValue={this.state.registerModel.city}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                value={this.state.registerModel.city}
                                                                name="city"
                                                                onChange={this.onChangeField}
                                                            />
                                                        </Form.Item>
                                                    </>
                                                }
                                                <Form.Item
                                                    label="How did you hear about VisualCron?"
                                                    name="howdidhear"
                                                    initialValue={this.state.registerModel.howdidhear}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select optionFilterProp="children" value={this.state.registerModel.howdidhear} className="btn" showSearch={true} placeholder="Please select" onChange={this.changeHowDidHear} >
                                                        {this.state.howHearTypes.map(howHearType => (
                                                            <Option key={howHearType.id} value={howHearType.id}>{howHearType.description}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        }
                                        {this.state.step === 2 &&
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Service location"
                                                    name="serviceLocation"
                                                    initialValue={this.state.registerModel.serviceLocation}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select mode="multiple" optionFilterProp="children" value={this.state.registerModel.serviceLocation} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeArrayEnumValue(e, 'serviceLocation')} >
                                                        {this.state.partnerServiceLocation.map(e => (
                                                            <Option key={e.id} value={e.id}>{e.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Service provided"
                                                    name="servicesProvided"
                                                    initialValue={this.state.registerModel.servicesProvided}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select mode="multiple" optionFilterProp="children" value={this.state.registerModel.servicesProvided} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeArrayEnumValue(e, 'servicesProvided')} >
                                                        {this.state.partnerServicesProvided.map(e => (
                                                            <Option key={e.id} value={e.id}>{e.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Business start year"
                                                    name="businessStartYear"
                                                    initialValue={this.state.registerModel.businessStartYear}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={this.state.registerModel.businessStartYear}
                                                        name="businessStartYear"
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Number of employees"
                                                    name="employeeCount"
                                                    initialValue={this.state.registerModel.employeeCount}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select optionFilterProp="children" value={this.state.registerModel.employeeCount} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeEnumValue(e, 'employeeCount')} >
                                                        {this.state.employeeCount.map(e => (
                                                            <Option key={e.id} value={e.id}>{e.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Number of customers"
                                                    name="customersCount"
                                                    initialValue={this.state.registerModel.customersCount}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select optionFilterProp="children" value={this.state.registerModel.customersCount} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeEnumValue(e, 'customersCount')} >
                                                        {this.state.employeeCount.map(e => (
                                                            <Option key={e.id} value={e.id}>{e.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Description"
                                                    name="description"
                                                    initialValue={this.state.registerModel.description}
                                                >
                                                    <Editor
                                                        tools={[
                                                            [Bold, Italic, Underline, Strikethrough],
                                                            [Subscript, Superscript],
                                                            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                                            [Indent, Outdent],
                                                            [OrderedList, UnorderedList],
                                                            FontSize,
                                                            FontName,
                                                            FormatBlock,
                                                            [Undo, Redo],
                                                            [Link, Unlink, InsertImage, ViewHtml],
                                                            [InsertTable],
                                                            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                                            [DeleteRow, DeleteColumn, DeleteTable],
                                                            [MergeCells, SplitCell]
                                                        ]}
                                                        contentStyle={{
                                                            height: 250,
                                                        }}
                                                        onChange={this.onChangeDescription}
                                                        defaultContent={this.state.registerModel.description}
                                                    />
                                                </Form.Item>

                                                <Form.Item >
                                                    <div className={'card-subsection center-placeholder '}>
                                                        <ReCAPTCHA
                                                            sitekey="6LeLLnEaAAAAAM0kDem-1dxshN359ZyjQf54DN8w"
                                                            onChange={this.onCaptchaChange}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            </Col>

                                        }
                                        <Col span={12} >
                                            {this.state.step !== 0 &&
                                                <Button onClick={this.prevStep}>
                                                    Back
                                                </Button>
                                            }
                                        </Col>
                                        <Col span={12} className="right-align">
                                            {this.state.step !== 2 &&
                                                <Button onClick={this.nextStep}>
                                                    Next
                                                </Button>
                                            }
                                            {this.state.step === 2 &&
                                                <Form.Item>
                                                    <Button
                                                        htmlType="submit"
                                                        type="primary"
                                                        disabled={!this.state.captchaPassed}
                                                        loading={this.state.registerLoading}
                                                    >
                                                        Register
                                                    </Button>
                                                </Form.Item>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </>
                    }
                </Col>
                {!this.props.lightVersion &&
                    <Col span={4} />
                }
            </Row>
        );
    }

    private nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    }

    private prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    }

    private getFilteredBusinessSubCategories = () => {
        const filteredBusinessSubCategories: BusinessSubCategoryModel[] = [];
        const unknown = this.state.businessSubCategories.find(x => x.parentId === null) as BusinessSubCategoryModel;
        if (unknown) {
            filteredBusinessSubCategories.push(unknown);
        }

        this.state.businessSubCategories.forEach(x => {
            if (x.parentId === this.state.registerModel.businessCategory) {
                filteredBusinessSubCategories.push(x);
            }
        });

        return filteredBusinessSubCategories;
    }

    private onCaptchaChange = (token: string | null) => {
        this.setState({ captchaPassed: token !== null });
    }

    private getPhonePrefixes = () => {
        const prefixes: string[] = [];
        const countries = this.state.countries;
        countries.sort((n1, n2) => n1.phonePrefix > n2.phonePrefix ? 1 : -1).forEach(country => {
            if (!prefixes.includes('+' + country.phonePrefix)) {
                prefixes.push('+' + country.phonePrefix);
            }
        });

        return prefixes;
    }

    private getCountry = (str: string) => {
        str = str.toLowerCase();
        let result = '';
        for (let i = 0; i < (str.length); i += 1) {
            if (str[i] !== ' ' && (str[i - 1] === ' ' || i === 0)) {
                result += (str[i]).toUpperCase();
            } else {
                result += str[i];
            }
        }
        return result;
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        const cookies = new Cookies();
        var c = cookies.get('c');
        if (!state.c) {
            if (c) {
                (state as any).c = c;
            }
        }

        this.setState(state, () => {
            this.setState({ registerLoaded: true });
        });
    }
}
