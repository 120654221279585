import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'antd/dist/antd.css';
import App from './modules/app/containers/App/App';
import { RootStore } from './shared/stores';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'mobx-react';
import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory();
const rootStore = new RootStore(history);

ReactDOM.render(
    <Provider {...rootStore}>
        <App history={history} />
    </Provider >,
    document.getElementById('root')
);
registerServiceWorker();

reportWebVitals();