/**
 * Class to operate with storage
 */

class StorageService {

    public storage: Storage;
    public tokenKey: string;

    constructor() {
        this.storage = window.localStorage;
        this.tokenKey = 'token';
    }

    public getToken() {
        return this.storage.getItem(this.tokenKey) || false;
    }

    public setToken(token: string) {
        if (token) {
            this.storage.setItem(this.tokenKey, token);
        }
    }

    public removeToken() {
        this.storage.removeItem(this.tokenKey);
    }
}
export default new StorageService();