import { ProfileStore } from './ProfileStore';
import { STORE_PROFILE } from './../../constants/stores';
import { History } from 'history';
import { STORE_ROUTER, STORE_USER } from 'src/constants';
import RouterStore from './RouterStore';
import { UserStore } from './UserStore';

export class RootStore {

    public [STORE_USER]: UserStore;
    public [STORE_PROFILE]: ProfileStore;
    public [STORE_ROUTER]: RouterStore;

    constructor(history: History) {
        this[STORE_ROUTER] = new RouterStore(history);
        this[STORE_USER] = new UserStore(this);
        this[STORE_PROFILE] = new ProfileStore(this);
    }
}
export default RootStore;