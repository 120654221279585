import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { STORE_USER } from 'src/constants';
import AnimationComponent from 'src/shared/components/AnimationComponent';
import { UserStore } from 'src/shared/stores/UserStore';
import RegisterLogin from '../RegisterLogin';

/** Stylesheet Imports */
import './RegisterPage.css';

export interface Props extends RouteComponentProps<{}> {
}

@inject(STORE_USER)
@observer
export default class RegisterPage extends React.Component<Props> {
    public userStore: UserStore = this.props[STORE_USER];
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="register-login-container">
                <AnimationComponent>
                    <RegisterLogin />
                    {this.userStore.authorized && window.location.replace('https://visualcron.com/myprofile.aspx')}
                </AnimationComponent>
            </div>
        );
    }
}
