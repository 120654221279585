import { LoadingOutlined } from '@ant-design/icons';
import * as React from 'react';

/** Stylesheet Imports */
import './LoaderComponent.css';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
}

export default class LoaderComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="loader-component">
                <LoadingOutlined translate={undefined} />
            </div>

        );
    }
}
