import { observable } from 'mobx';

export default class FilterViewModel {
    @observable public companyName: string;
    @observable public serviceProvided: number[];
    @observable public serviceLocation: number[];
    @observable public marketingMethod: number[];
    @observable public country: string;
    @observable public city: string;
    @observable public featured: boolean;

    constructor() {
        this.companyName = '';
        this.country = 'All';
        this.city = 'All';
        this.featured = false;
        this.serviceProvided = [1, 2, 3, 4, 5, 6];
        this.serviceLocation = [1, 2];
        this.marketingMethod = [-1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    }
}