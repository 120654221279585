import * as React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';
import { History } from 'history';

import './App.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { inject, observer } from 'mobx-react';
import HomeContainer from '../../../home/containers/HomeContainer';
import { STORE_ROUTER, STORE_USER } from '../../../../constants';
import { UserStore } from '../../../../shared/stores/UserStore';
import RegisterPage from '../../components/RegisterPage';
import ProfileContainter from 'src/modules/home/containers/ProfileContainter';
import SearchContainer from 'src/modules/home/containers/SearchContainer';
import RouterStore from 'src/shared/stores/RouterStore';

export interface AppProps {
    history: History<{}>;
}

@inject(STORE_ROUTER, STORE_USER)
@observer
export class App extends React.Component<AppProps> {
    public userStore: UserStore = this.props[STORE_USER];
    public router: RouterStore = this.props[STORE_ROUTER];

    componentDidMount() {
        this.userStore.getUser();
    }

    public render() {
        return (
            <div className="app-container">
                {this.getContent()}
            </div>
        );
    }

    private getContent = () => {
        return <>
            <Router history={this.router.history}>
                <Header />
                <Switch>
                    <div className="main-container container">
                        <Route
                            exact={true}
                            path="/"
                            component={withRouter(HomeContainer)}
                        />
                        <Route path="/test" component={withRouter(HomeContainer)} />
                        <Route path="/register" component={withRouter(RegisterPage)} />
                        <Route path="/login" component={withRouter(RegisterPage)} />
                        <Route path="/profile::id" component={withRouter(ProfileContainter)} />
                        <Route path="/search" component={withRouter(SearchContainer)} />
                    </div>
                </Switch>
                <Footer />
            </Router>
        </>;
    }
}

export default App;
