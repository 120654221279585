import * as React from 'react';

/** Stylesheet Imports */
import './MobileNavigation.css';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    showNavbarMenu: boolean;
    dropdownOpen: {
        product: boolean,
        features: boolean,
        resources: boolean,
        about: boolean,
    },
}

export default class Menu extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showNavbarMenu: false,
            dropdownOpen: {
                product: false,
                features: false,
                resources: false,
                about: false,
            }
        };
    }

    updateShowNavbarMenu = () => {
        this.setState(prevState => ({
            showNavbarMenu: !this.state.showNavbarMenu
        }))
    }

    toggleDropdown = (dropdown: string) => {
        this.setState(prevState => ({
            dropdownOpen: {
                product: dropdown === 'product' && !this.state.dropdownOpen.product,
                features: dropdown === 'features' && !this.state.dropdownOpen.features,
                resources: dropdown === 'resources' && !this.state.dropdownOpen.resources,
                about: dropdown === 'about' && !this.state.dropdownOpen.about,
            }
        }
        ));
    };

    render() {
        return (

            <div><div className="navbar-mobile_button_bar">
                <a className="mobile-search" href="/Search.aspx">
                    <span className="mobile_navigation-search" ></span>
                </a>
                <div className="mobile-menu">
                    <button type="button" id="toggle" className="mobile_navigation-menu" onMouseDown={this.updateShowNavbarMenu}><span className="navbar-bars"></span></button>
                </div>
            </div>
                <div className={(this.state.showNavbarMenu ? 'open ':'') + "mobile_navigation"} id="mobile_navigation">
                    <div className="mobile_navigation-item">
                        <div className={(this.state.dropdownOpen.product ? 'open ':'') + "mobile_navigation-header"}>
                            <button onMouseDown={(e) => this.toggleDropdown('product')} type="button" data-toggle="collapse" data-target="#product" className="header-button">
                                <div>Product</div>
                                <span className={(this.state.dropdownOpen.product ? 'rotated ':'') +"chevron-right align-right"}></span>
                            </button>
                        </div>
                        <div id="product" className="mobile_navigation-collapsible collapse">
                            <div>
                                <div className="mobile-category_header mobile-column__top">VisualCron</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/about.aspx">What is VisualCron?</a></li>
                                    <li><a href="https://visualcron.com/Why-Choose-VisualCron.aspx">Why choose VisualCron?</a></li>
                                    <li><a href="https://visualcron.com/choosinglicense.aspx">Choosing the right license</a></li>
                                    <li><a href="https://visualcron.com/basicvsprofessional.aspx">Compare licenses</a></li>
                                    <li><a href="https://visualcron.com/licenseguide.aspx">License guide</a></li>
                                </ul>
                            </div>
                            <hr />
                            <div className="col-md-4">
                                <div className="mobile-category_header dropdown-title">Request a Demo</div>
                                <p className="dropdown-desc">Get a customized tour of VisualCron’s automation, integration, and task scheduling capabilities.</p>
                                <a href="https://visualcron.com/request-demo.aspx" className="button button-blue">Request a Demo</a>
                            </div>
                            <hr />
                            <div className="col-md-4">
                                <div className="mobile-category_header dropdown-title ">Download Free Trial</div>
                                <p className="dropdown-desc">Explore VisualCron’s full power at your own pace with a free, all-access trial for 30 days.</p>
                                <a href="https://visualcron.com/download.aspx" className="button button-yellow">Start Free Trial</a>
                            </div>
                        </div>
                    </div>
                    <div className="mobile_navigation-item">
                        <div className={(this.state.dropdownOpen.features ? 'open ':'') + "mobile_navigation-header"}>
                            <button onMouseDown={(e) => this.toggleDropdown('features')} type="button" data-toggle="collapse" data-target="#features" className="header-button">
                                <div>Features</div>
                                <span className={(this.state.dropdownOpen.features ? 'rotated ':'') +"chevron-right align-right"}></span>
                            </button>

                        </div>
                        <div id="features" className="mobile_navigation-collapsible collapse">
                            <div>
                                <div className="mobile-category_header mobile-column__top">Overview</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/automation.aspx">General automation</a></li>
                                    <li><a href="https://visualcron.com/Job-Scheduling-Software.aspx">Enterprise Job Scheduling</a></li>
                                    <li><a href="https://visualcron.com/tasks.aspx">Tasks</a></li>
                                    <li><a href="https://visualcron.com/triggers.aspx">Triggers</a></li>
                                    <li><a href="https://visualcron.com/scheduler.aspx">Task Scheduler</a></li>
                                    <li><a href="https://visualcron.com/connections.aspx">Connections</a></li>
                                    <li><a href="https://visualcron.com/api.aspx">API</a></li>
                                </ul>
                            </div>
                            <div>
                                <div className="mobile-category_header mobile-column ">BPA</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/business-process-automation.aspx">Business Process Automation (BPA)</a></li>
                                    <li><a href="https://visualcron.com/powershell-automation.aspx">PowerShell automation</a></li>
                                    <li><a href="https://visualcron.com/cloud-automation.aspx">Cloud automation</a></li>
                                    <li><a href="https://visualcron.com/encryption-automation.aspx">Encryption automation</a></li>
                                    <li><a href="https://visualcron.com/sql-database-automation.aspx">SQL / database automation</a></li>
                                    <li><a href="https://visualcron.com/pdf-automation.aspx">PDF automation & data extraction</a></li>
                                    <li><a href="https://visualcron.com/xml-automation.aspx">XML automation & manipulation</a></li>
                                    <li><a href="https://visualcron.com/office-macro-automation.aspx">Office 365, Excel & Macro Automation</a></li>
                                    <li><a href="https://visualcron.com/sharepoint-automation.aspx">Sharepoint automation</a></li>
                                    <li><a href="https://visualcron.com/7z-zip-rar-archive-automation.aspx">Archive automation</a></li>
                                    <li><a href="https://visualcron.com/vm-virtual-machine-automation.aspx">Virtual Machine Automation</a></li>
                                    <li><a href="https://visualcron.com/scan-document-ocr-invoice.aspx">Document scanning / OCR</a></li>
                                    <li><a href="https://visualcron.com/workflowmanagementsoftware.aspx">Workflow Management</a></li>

                                </ul>
                            </div>
                            <div>
                                <div className="mobile-category_header mobile-column ">MFT</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/mft-managed-file-transfer.aspx">Managed File Transfer (MFT)</a></li>
                                    <li><a href="https://visualcron.com/ftp-sftp-scp-automation.aspx">FTP/SFTP/SCP automation</a></li>
                                    <li><a href="https://visualcron.com/exe-batch-automation.aspx">Batch & exe automation</a></li>
                                    <li><a href="https://visualcron.com/etl-extract-transform-load.aspx">Extract Transform Load (ETL)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mobile_navigation-item">
                        <div className={(this.state.dropdownOpen.resources ? 'open ':'') + "mobile_navigation-header"}>
                            <button onMouseDown={(e) => this.toggleDropdown('resources')} type="button" data-toggle="collapse" data-target="#resources" className="header-button">
                                <div>
                                    Resources
                                </div>
                                <span className={(this.state.dropdownOpen.resources ? 'rotated ':'') +"chevron-right align-right"}></span>
                            </button>

                        </div>
                        <div id="resources" className="mobile_navigation-collapsible collapse">
                            <div>
                                <div className="mobile-category_header mobile-column__top">Resources</div>
                                <ul className="mobile_navigation-list">
                                    <li>
                                        <a href="https://visualcron.com/blog/default.aspx">Blog</a>
                                    </li>
                                    <li><a href="https://visualcron.com/forum.aspx">Community forum</a></li>
                                    <li><a href="https://visualcron.com/documentation.aspx">Documentation</a></li>
                                    <li><a href="https://visualcron.com/forum.aspx">Community forum</a></li>
                                    <li><a href="https://visualcron.com/tutorials.aspx">Video tutorials</a></li>
                                </ul>
                            </div>
                            <div>
                                <div className="mobile-category_header mobile-column ">System Support</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/support.aspx">Options</a></li>
                                    <li><a href="https://visualcron.com/requirements.aspx">System Requirements</a></li>
                                    <li><a href="https://visualcron.com/changelog.aspx">Change Log</a></li>
                                    <li><a href="https://visualcron.com/contact.aspx">Contact Support</a></li>
                                </ul>
                            </div>
                            <div>
                                <div className="mobile-category_header mobile-column ">Order Support</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/getpricequote.aspx">Price Quotes</a></li>
                                    <li><a href="https://visualcron.com/poinvoice.aspx">POs/Invoicing</a></li>
                                    <li><a href="https://visualcron.com/paymentinformation.aspx">Payment Information</a></li>
                                    <li><a href="https://visualcron.com/returnpolicy.aspx">Return Policy</a></li>
                                    <li><a href="https://visualcron.com/resellerinformation.aspx">Resellers</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="mobile_navigation-item">
                        <div className={(this.state.dropdownOpen.about ? 'open ':'') + "mobile_navigation-header"}>
                            <button onMouseDown={(e) => this.toggleDropdown('about')} type="button" data-toggle="collapse" data-target="#about" className="header-button">
                                <div>
                                    About
                                </div>
                                <span className={(this.state.dropdownOpen.about ? 'rotated ':'') +"chevron-right align-right"}></span>
                            </button>

                        </div>
                        <div id="about" className="mobile_navigation-collapsible collapse">
                            <div>
                                <div className="mobile-category_header mobile-column__top">About Us</div>
                                <ul className="mobile_navigation-list">
                                    <li><a href="https://visualcron.com/customers.aspx">Customers</a></li>
                                    <li><a href="https://partners.visualcron.com/">Partners</a></li>
                                    <li><a href="https://visualcron.com/testimonials.aspx">Testimonials</a></li>
                                    <li><a href="https://visualcron.com/awards.aspx">Awards</a></li>
                                    <li><a href="https://visualcron.com/contact.aspx">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="pricing" className="mobile_navigation-item__pricing"><a href="https://visualcron.com/order.aspx">Pricing</a></div>
                    <div className="mobile_navigation-item__rectangle"></div>
                    <a href="https://visualcron.com/contact.aspx">
                        <div id="contactSales" className="mobile_navigation-button__blue">
                            Contact Sales
                        </div>
                    </a>
                </div>
    
            </div>
        );
    }
}
