import * as React from 'react';
import { RouteComponentProps } from 'react-router';

/** Stylesheet Imports */
import './SearchContainer.css';
import SearchComponent from '../../components/SearchComponent';
import AnimationComponent from 'src/shared/components/AnimationComponent';

export interface Props extends RouteComponentProps<{}> {
}

export default class SearchContainer extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="search-profile-container">
                <AnimationComponent>
                    <SearchComponent showAll={true} />
                </AnimationComponent>
            </div>
        );
    }
}
