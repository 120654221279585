const api = {
    getUser: '/auth/getuser',
    getAdminStatus: '/auth/getadminstatus',
    getProfile: '/profile/getprofile',
    getProfiles: '/profile/getprofiles',
    getLogos: '/profile/getlogos',
    getLocation: '/profile/getlocation',

};

export default api;