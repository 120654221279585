import { action, computed, observable } from 'mobx';
import { RootStore } from '.';
import UserService from 'src/services/UserService';
import Cookies from 'js-cookie';

export class UserStore {
    @observable public username: string;
    @observable public loading: boolean;

    @observable public loadingUserSettings: boolean;
    @observable public isAdmin: boolean;

    private api: UserService;

    constructor(rootStore: RootStore) {
        this.api = new UserService();

        this.username = '';
        this.loading = true;

        this.loadingUserSettings = true;
        this.isAdmin = false;

    }

    @computed
    public get authorized(): boolean {
        return this.username.length > 0;
    }

    @action
    public getUser = () => {
        this.loading = true;
        try {
            const auth = Cookies.get('YAFNETAuthentication');
            if (auth && auth !== null && auth !== 'null') {
                const origin = window.location.origin.replace('partners.', '');
                this.api.getUser(origin, auth).then(
                    (data: string) => {
                        this.username = data;
                        this.loading = false;

                        this.getAdminStatus(data);
                    });
            } else {
                this.loading = false;
            }
        } catch (err) {
            console.log(err);
            this.loading = false;
        }
    }

    @action
    public getAdminStatus = (user: string) => {
        this.api.getAdminStatus(user).then(
            (data: boolean) => {
                this.isAdmin = data;
            });
    }

    public redirectToLogin = () => {
        const link = 'https://www.visualcron.com/login.aspx?r=/redirectwithauth.aspx';
        const element = document.createElement('a');
        element.href = link;
        element.click();
    }
}