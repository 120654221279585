import { action, observable } from 'mobx';
import { RootStore } from '.';
import ProfileService from 'src/services/ProfileService';
import ProfileViewModel from 'src/models/ProfileViewModel';
import FilterViewModel from 'src/models/FilterViewModel';
import SearchViewModel from 'src/models/SearchViewModel';
import LogoViewModel from 'src/models/LogoViewModel';

export class ProfileStore {
    @observable public loading: boolean;
    @observable public currentProfile: ProfileViewModel;

    @observable public loadingFilter: boolean;
    @observable public filter: FilterViewModel;
    @observable public searchResults: SearchViewModel[];
    @observable public page: number;
    @observable public offset: number;

    private api: ProfileService;

    constructor(rootStore: RootStore) {
        this.api = new ProfileService();

        this.loading = true;
        this.currentProfile = new ProfileViewModel();
        this.filter = new FilterViewModel();
        this.loadingFilter = true;
        this.searchResults = [];
        this.page = 1;
        this.offset = 24;
    }

    @action
    public getProfile = (customerId: string) => {
        this.loading = true;
        this.currentProfile = new ProfileViewModel();
        this.api.getProfile(customerId).then(
            (data: ProfileViewModel) => {
                this.currentProfile = data;
                this.loading = false;
            });
    }

    @action
    public getLocation = () => {
        this.api.getLocation().then(
            (data: string) => {
                if (data) {
                    this.filter.country = data;
                }
                this.getProfiles();
            });
    }

    @action
    public getProfiles = () => {
        this.loadingFilter = true;
        this.api.getProfiles(JSON.stringify(this.filter)).then(
            (data: SearchViewModel[]) => {
                this.searchResults = data;
                this.loadingFilter = false;
                this.getLogos();
            });
    }

    @action
    public getLogos = () => {
        const ids = this.searchResults.slice((this.page - 1) * this.offset, (this.page - 1) * this.offset + this.offset).filter(x => x.logoLoaded === false).map(x => x.customerId);
        this.getLogosById(ids);
    }

    @action
    public getLogosById = (customerIds: string[]) => {
        if (customerIds.length !== 0) {
            this.api.getLogos(JSON.stringify(customerIds)).then(
                (data: LogoViewModel[]) => {
                    customerIds.forEach(customerId => {
                        const logoItem = data.find(x => x.customerId === customerId);
                        if (logoItem) {
                            const item = this.searchResults.find(x => x.customerId === logoItem.customerId);
                            if (item) {
                                console.log(item);
                                item.logo = logoItem.logo;
                                item.logoLoaded = true;
                            }
                        } else {
                            const item = this.searchResults.find(x => x.customerId === customerId);
                            if (item) {
                                item.logoLoaded = true;
                            }
                        }
                    });
                });
        }
    }
}