import api from '../constants/api';
import ApiService from './ApiService';

export default class UserService extends ApiService {

    public async getUser(origin: string, value: string) {
        const res = await this.apiCall(
            api.getUser + '?origin=' + origin + '&value=' + value,
            'GET'
        );
        this.handleCommonError(res, false);

        return res.body;
    }

    public async getAdminStatus(user: string) {
        const res = await this.apiCall(
            api.getAdminStatus + '?user=' + user,
            'GET'
        );
        this.handleCommonError(res, false);

        return res.body;
    }

}