import { observable } from 'mobx';
import ProfileContactViewModel from '../ProfileContactViewModel';

export default class ProfileViewModel {
    public customerId: string;
    public logo: string;
    @observable public customerName: string;
    public description: string;
    public country: string;
    public countryCode: string;
    public city: string;
    public serviceProvided: string[];
    public serviceLocation: string[];
    public marketingMethods: string[];
    public contacts: ProfileContactViewModel[];

    constructor() {
        this.serviceProvided = [];
        this.serviceLocation = [];
        this.marketingMethods = [];
        this.contacts = [];
    }
}