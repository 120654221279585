import { Col, Row, Table, Tag } from 'antd';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { STORE_PROFILE, STORE_USER } from 'src/constants';
import SpinLoader from 'src/shared/components/SpinLoader';
import { ProfileStore } from 'src/shared/stores/ProfileStore';
import ReactCountryFlag from 'react-country-flag';

/** Stylesheet Imports */
import './ProfileContainter.css';
import AnimationComponent from 'src/shared/components/AnimationComponent';
import { UserStore } from 'src/shared/stores/UserStore';

export interface Props extends RouteComponentProps<{}> {
}

@inject(STORE_PROFILE, STORE_USER)
@observer
export default class ProfileContainter extends React.Component<Props> {
    public profileStore: ProfileStore = this.props[STORE_PROFILE];
    public userStore: UserStore = this.props[STORE_USER];

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        const idname = 'id';
        const id = this.props.match.params[idname];
        this.profileStore.getProfile(id);
    }

    render() {
        return (
            <div className="profile-container">
                <AnimationComponent>
                    {this.profileStore.loading &&
                        <SpinLoader />
                    }
                    {!this.profileStore.loading &&
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                {this.userStore.isAdmin &&
                                    <h2><a target="_blank" href={'https://www.visualcron.com/adm/editcompany.aspx?id=' + this.profileStore.currentProfile.customerId} rel="noreferrer">{this.profileStore.currentProfile.customerName}</a></h2>
                                }
                                {!this.userStore.isAdmin &&
                                    <h2>{this.profileStore.currentProfile.customerName}</h2>
                                }
                            </Col>
                            <Col span={12} className="right-align" >
                                {this.profileStore.currentProfile.logo &&
                                    <img alt="logo" className="customer-logo" src={'data:image/jpeg;base64,' + this.profileStore.currentProfile.logo} />
                                }
                            </Col>

                            <Col span={24}>
                                <p>
                                    <ReactCountryFlag
                                        countryCode={this.profileStore.currentProfile.countryCode}
                                        svg={true}
                                        style={{
                                            height: '16px',
                                            marginTop: -2
                                        }}
                                        cdnSuffix="svg"
                                    />
                                    {' ' + this.titleCase(this.profileStore.currentProfile.country) + (this.profileStore.currentProfile.city ? ', ' + this.profileStore.currentProfile.city : '')}
                                </p>
                                {this.profileStore.currentProfile.serviceProvided &&
                                    <>
                                        <p className="subtitle">Services provided</p>
                                        <p>
                                            {this.profileStore.currentProfile.serviceProvided.map(x => (
                                                <Tag key={x}>{x}</Tag>
                                            ))}
                                        </p>
                                    </>
                                }
                                {this.profileStore.currentProfile.serviceLocation &&
                                    <>
                                        <p className="subtitle">Service location</p>
                                        <p>
                                            {this.profileStore.currentProfile.serviceLocation.map(x => (
                                                <Tag key={x}>{x}</Tag>
                                            ))}
                                        </p>
                                    </>
                                }
                                {this.userStore.isAdmin && this.profileStore.currentProfile.marketingMethods &&
                                    <>
                                        <p className="subtitle">Marketing methods</p>
                                        <p>
                                            {this.profileStore.currentProfile.marketingMethods.map(x => (
                                                <Tag key={x}>{x}</Tag>
                                            ))}
                                        </p>
                                    </>
                                }
                            </Col>

                            {this.profileStore.currentProfile.description &&
                                <Col span={24}>
                                    <p className="subtitle">Description</p>
                                    <div dangerouslySetInnerHTML={{ __html: this.profileStore.currentProfile.description }} />
                                </Col>
                            }

                            <Col span={24}>
                                <p className="subtitle">Contact details</p>
                                <Table
                                    pagination={false}
                                    columns={[
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            key: 'name',
                                            render: (text, record) => <>{record.firstName + ' ' + record.lastName}</>,
                                        },
                                        {
                                            title: 'Email',
                                            dataIndex: 'email',
                                            key: 'email',
                                            render: (text, record) => <a href={'mailto:' + record.email}>{record.email}</a>,
                                        },
                                        {
                                            title: 'Phone',
                                            dataIndex: 'phone',
                                            key: 'phone',
                                        },
                                        {
                                            title: 'Role',
                                            dataIndex: 'role',
                                            key: 'role',
                                        },
                                    ]}
                                    dataSource={this.profileStore.currentProfile.contacts}
                                />
                            </Col>
                        </Row >
                    }
                </AnimationComponent>
            </div>
        );
    }

    private titleCase = (str: string) => {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
}
