import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Stylesheet Imports */
import './Header.css';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { STORE_PROFILE, STORE_ROUTER, STORE_USER } from 'src/constants';
import { UserStore } from 'src/shared/stores/UserStore';
import RouterStore from 'src/shared/stores/RouterStore';
import BreadcrumbsViewModel from 'src/shared/models/BreadcrumbsViewModel';
import { ProfileStore } from 'src/shared/stores/ProfileStore';
import Menu from 'src/shared/components/Menu';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    pathname: string;
}

@inject(STORE_USER, STORE_ROUTER, STORE_PROFILE)
@observer
export default class Header extends React.Component<Props, State> {
    public userStore: UserStore = this.props[STORE_USER];
    public routerStore: RouterStore = this.props[STORE_ROUTER];
    public profileStore: ProfileStore = this.props[STORE_PROFILE];

    constructor(props: Props) {
        super(props);
        this.state = {
            pathname: ''
        };
    }

    componentDidMount() {
        this.onLocationChange();
        this.routerStore.onLocationChange = this.onLocationChange;

    }

    render() {
        return (
            <>
                <section className="topbar topbar-dark clearfix social-top">
                    <div className="container ">

                    </div>
                </section>
                <Menu />
                <section className="header-breadcrumb dm-shadow clearfix">
                    <div className="header-container">
                        <ul className="breadcrumb breadcrumb-list">
                            {this.getBreadcrumbs().map((breadcrumb, index) => (
                                <>
                                    <li key={breadcrumb.name}>
                                        <Link
                                            to={breadcrumb.url}
                                        >
                                            {breadcrumb.name}
                                        </Link>
                                    </li>
                                    {index !== this.getBreadcrumbs().length - 1 &&
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    }
                                </>
                            ))}
                        </ul>
                    </div>
                </section>
            </>
        );
    }

    private onLocationChange = () => {
        let pathname = window.location.pathname;

        if (pathname.includes(':')) {
            pathname = pathname.split(':')[0];
        }

        this.setState({ pathname }, () => {
            // if (this.getBreadcrumbs().length > 1) {
            //     document.title = 'VisualCron | Academy - ' +
            //         this.getBreadcrumbs()[this.getBreadcrumbs().length - 1].name;
            // } else {
            //     document.title = 'VisualCron | Academy';
            // }
        });
    }

    private getBreadcrumbs = (): BreadcrumbsViewModel[] => {
        const elems: BreadcrumbsViewModel[] = [];

        const pathname = this.state.pathname;

        elems.push(
            new BreadcrumbsViewModel('Home', '/')
        );

        switch (pathname) {
            case '/profile':
                const n = this.profileStore.currentProfile.customerName;

                elems.push(
                    new BreadcrumbsViewModel('Find a partner', '/search'),
                    new BreadcrumbsViewModel((n ? 'Partner - ' + n : 'Partner'), '')
                );
                break;
            case '/search':
                elems.push(
                    new BreadcrumbsViewModel('Find a partner', '/search')
                );
                break;
            case '/register':
                elems.push(
                    new BreadcrumbsViewModel('Register', '/register')
                );
                break;
            case '/login':
                elems.push(
                    new BreadcrumbsViewModel('Login', '/login')
                );
                break;
            default:
                break;
        }

        return elems;
    }
}
